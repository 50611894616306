import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  padding-top: 0;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  z-index: 0;

  @media only screen and (max-width: 991px) {
  }

  @media only screen and (max-width: 810px) {
    padding-bottom: 56.25% /* 16:9 */;
    height: 0;
    margin: 0px !important;
    width: 100%;
  }

  @media only screen and (max-width: 478px) {
    padding-bottom: 56.25% /* 16:9 */;
    width: 100%;
    height: 0;
    margin: 0px !important;
  }
`;

const VideoIframe = styled.iframe`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
`;

const Video = ({
  videoSrcURL,
  videoTitle,
  width,
  height,
  style,
}: {
  videoSrcURL: string;
  videoTitle: string;
  width: number;
  height: number;
  style?: any;
}) => {
  return (
    <VideoContainer width={width} height={height} style={style}>
      <VideoIframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen={true}
      />
    </VideoContainer>
  );
};
export default Video;
