import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { useIsMounted } from "../../util/hooks";
import * as marked from "marked";
import sanitize from "sanitize-html";

const FaqItemRoot = styled.div`
  color: ${colors.lightBlue};
  font-size: 14pt;
  min-height: 56px;
  width: 100%;
`;
const FaqContentWrapper = styled(animated.div)`
  min-height: 0px;
  height: ${({ expand }) => expand};
  overflow: hidden;
  transition: 100ms;
`;

const FaqContent = styled.div`
  transition: 100ms;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FaqItemTitle = styled.a`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const FaqQuestionWrapper = styled.div`
  display: flex;
`;

const FaqItem = ({ title, content }: { title: string; content: string }) => {
  const defaultHeight = 0;
  const isMounted = useIsMounted();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(defaultHeight);
  const [ref, { height }] = useMeasure();

  const duration = 100;

  const expand = useSpring({
    config: {
      duration,
    },
    height: expanded ? `${contentHeight}px` : `${defaultHeight}px`,
  });

  const spin = useSpring({
    config: {
      duration,
    },
    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
  });

  const updateContentHeight = () => {
    isMounted && setContentHeight(height + 40);
  };

  useEffect(() => {
    setContentHeight(height + 40);
    window.addEventListener("resize", updateContentHeight);
    return window.removeEventListener("resize", updateContentHeight);
  }, [height]);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const markdown = sanitize(marked.parse(content));

  return (
    <FaqItemRoot>
      <FaqQuestionWrapper onClick={toggleExpanded}>
        <FaqItemTitle>{title}</FaqItemTitle>
        <animated.div style={spin}>
          <FontAwesomeIcon color={colors.orange} icon={faAngleDown} />
        </animated.div>
      </FaqQuestionWrapper>
      <FaqContentWrapper style={expand}>
        <FaqContent ref={ref}>
          <div dangerouslySetInnerHTML={{ __html: markdown }} />
        </FaqContent>
      </FaqContentWrapper>
    </FaqItemRoot>
  );
};

export default FaqItem;
