import React from "react";
import styled from "styled-components";
import FaqItem from "./FaqItem";

const FaqWrapper = styled.div`
  max-width: 800px;
`;

interface FaqQuestion {
  question: string;
  answer: string;
}

const FaqContainer = ({ data }: { data: FaqQuestion[] }) => {
  return (
    <FaqWrapper>
      {data.map((faq, i) => (
        <FaqItem key={`${i}_faq`} title={faq.question} content={faq.answer} />
      ))}
    </FaqWrapper>
  );
};

export default FaqContainer;
